<template>
  <transition name="modal">
    <div v-if="isOpen" class="modal__mask">
      <div v-click-outside="close" class="modal__container" :class="modalSize">
        <h4 v-if="$slots.title">
          <slot name="title"></slot>
        </h4>

        <div class="modal__body">
          <p v-if="$slots.description" class="modal__description">
            <slot name="description" />
          </p>

          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "Modal",

  props: {
    size: {
      default: "sm",
      type: String
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    isOpen: false
  }),

  computed: {
    modalSize() {
      switch (this.size) {
        case "sm":
          return "modal__container--sm";

        case "md":
          return "modal__container--md";

        case "lg":
          return "modal__container--lg";

        default:
          return "modal__container--sm";
      }
    }
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>
.modal__mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal__container {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--light-gray);
  border-radius: var(--lengthSm1);
  transition: all 0.3s ease;
  overflow-y: hidden;
}

.modal__container--sm {
  max-width: 480px;
  max-height: 95%;
  padding: var(--lengthMd3);
}

.modal__container--md {
  max-width: 768px;
  height: 95%;
  padding: var(--lengthLg1);
}

.modal__container--lg {
  max-width: 1024px;
  height: 95%;
  padding: var(--lengthLg1);
}

h4 {
  padding-bottom: var(--lengthMd3);
}

.modal__body {
  overflow: auto;
}

.modal__description {
  font-weight: var(--medium);
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms;
}

.modal-enter-to {
  opacity: 1;
}
</style>
